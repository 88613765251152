import React from 'react'
import SEO from "components/seo"
import Heading from "components/Heading"
import HeroImageFull from "components/HeroImageFull"
import Interweave from 'interweave'

const ViewPrivacyPolicy = ({pageData}) => {
  const { heading, page, content } = pageData;

  return (
    <React.Fragment>
      <SEO title={page} />
      <div className="max-container real-hero wrapper--privacy--hero">
        <HeroImageFull
          heroTextClassname="mobile--is-center"
          imageName="privacyPolicyHeroImage"
          imageProps={{
            imgStyle: {
              objectPosition: "center top",
            },
          }}
        >
          <Heading className="is-heading">
            <Interweave noWrap content={heading} />
          </Heading>
        </HeroImageFull>
      </div>

      <div className="max-container wrapper--privacy--block-content">
        <div className="container max-1048">
          <div className="columns">
            <div className="column is-full">
              <div className="wrapper--user-content-slot">
                <Interweave noWrap content={content} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ViewPrivacyPolicy