import React from "react"
import Layout from "components/layout"
import usePageData from 'hooks/queryPrivacyPolicy';
import ViewPrivacyPolicy from 'components/ViewPrivacyPolicy';

const PrivacyPolicyPage = props => {
  const { pageContext } = props;
  const pageData = usePageData();

  return (
    <Layout pageContext={pageContext}>
      <ViewPrivacyPolicy pageData={pageData} />
    </Layout>
  )
}

export default PrivacyPolicyPage
